<template>
    <div class="ParkedTitle"><span><h1>flynntynan.co.uk</h1></span></div>
</template>

<style scoped>
.ParkedTitle {
  text-align: center;
  color: #10ac84;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Courier New', Courier, monospace;
  font-size: 25px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  filter: drop-shadow(5px 5px 3px black);
}
</style>