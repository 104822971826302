<template>
  <div class="background-image"></div>
  <ParkedTitle/>
</template>

<script>
import ParkedTitle from './components/ParkedTitle.vue';

export default {
  name: 'App',
  components: {
    ParkedTitle
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: hsla(212, 29%, 19%, 1);
  /* background: linear-gradient(10deg, hsla(212, 29%, 19%, 1), hsla(260, 25%, 48%, 1), hsla(213, 35%, 66%, 1)); */
}
</style>

